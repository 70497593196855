.fj-gallery {
  position: relative;
  overflow: hidden;
}
.fj-gallery::after {
  content: "";
  display: block;
  clear: both;
}
.fj-gallery .fj-gallery-item {
  float: left;
  top: 0;
  left: 0;
}
.fj-gallery .fj-gallery-item > img {
  display: block;
  width: 100%;
  height: auto;
}
